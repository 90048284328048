import { MyIcon, useLocation, get, config } from "@/common";
import { Button, message, Popover, Upload } from "antd";

function Main(props: any) {
    const location = useLocation()
    const pathname = props.apiUrl || location.pathname
    const btn_name = props.btnName || '导入'
    const btn_temple = props.btnTemple || '下载导入模板'
    const dataImport = () => {
    }
    const downloadTemple = () => {
        get(pathname + '/download_temple', null, 'blob').then(res => {
            res.blob.then((data: any) => {
                let filename = decodeURIComponent(res.headers.get('Content-Disposition').match(/filename=(.*);/)[1])
                var url = window.URL.createObjectURL(data);
                var a = document.createElement('a');
                a.href = url;
                a.download = filename
                a.click();
            })
        })
    }
    const content = <div>
        <Button onClick={downloadTemple} size="small">{btn_temple}</Button>
    </div>
    const btn = (props.type && props.type === 'ico') ? <MyIcon type="icon-daoru1" title={btn_name} onClick={dataImport} /> : <Button onClick={dataImport}>{btn_name}</Button>
    let member = {
        token: ''
    }
    try {
        member = JSON.parse(localStorage.getItem('member') || '{}')
    } catch (error) {

    }
    const onChange = (info: any) => {
        if (info.file.status === 'done') {
            let rs = info.file.response
            if (!rs.code) message.success(rs.msg)
            props.nref.list.current.getList()
        }
    }
    return (
        <Popover placement="topRight" content={content} title={props.title || "其它操作"}>
            <Upload
                accept='.xls, .xlsx, .csv'
                headers={{
                    'Token-Member': member.token
                }}
                action={config.api.url.default + pathname + '/import'}
                onChange={onChange}
                showUploadList={false}
            >
                {btn}
            </Upload>
        </Popover>
    )
}

export default Main