import { useState, useEffect, forwardRef, get, useRef } from '@/common'
import { Layout, Form, Input, Space, Button, Radio, Table, Select, Row, Col, Tag } from 'antd';
import './FormTable.scss';

function Main(props: any, ref: any) {

    const [dataSource, setDataSource] = useState([])
    const [selected_row_keys, setSelectedRowKeys] = useState<any>([]);
    const [default_status, setDefaultStatus] = useState(true)
    const [total, setTotal] = useState(0);
    const request = useRef<any>({
        page: 1,
        page_size: 10
    })

    const constants = JSON.parse(localStorage.getItem('constants') || '{}')

    const columns: any = [
        {
            title: '商品ID',
            dataIndex: 'id',
            align: 'center',
        },
        {
            title: '商品分类',
            align: 'center',
            render: (text: string, record: any) => (
                record.classify_ids_text ?
                    <>
                        {
                            record.classify_ids_text.map((e: any, k: number) => {
                                return <Tag color="geekblue" key={k}>{e}</Tag>
                            })
                        }
                    </>
                    : <span className='gray'>无</span>
            )
        },
        {
            title: '商品标题',
            dataIndex: 'title',
            align: 'center',
        },
        {
            title: '状态',
            align: 'center',
            render: (text: string, record: any) => (
                <span>{constants.goods.status[record.status]}</span>
            )
        },
    ]

    useEffect(() => {
        if (default_status && props.value) {
            setSelectedRowKeys(props.value)
            setDefaultStatus(false)
        }
    }, [props.value])

    const getList = () => {
        get(props.apiUrl, request.current).then(data => {
            setDataSource(data.data)
            if (data.page) {
                setTotal(data.page.total)
            }
        })
    }

    useEffect(() => {
        getList()
    }, [])

    return (
        <>
            <Space className='search'>
                <div className='item'>
                    <div>商品标题：</div>
                    <Input placeholder='请输入商品标题' onChange={(e) => {
                        request.current.title = e.target.value
                    }} />
                </div>
                <Button type="primary" onClick={() => {
                    getList()
                }}>搜索</Button>
            </Space>
            <Table
                columns={columns}
                rowSelection={(props.rowSelection === false) ? undefined : {
                    selectedRowKeys: selected_row_keys, onChange: (k) => {
                        setSelectedRowKeys(k)
                        props.onChange(k)
                    }
                }}
                dataSource={dataSource}
                rowKey='id'
                pagination={{
                    hideOnSinglePage: true,
                    total,
                    current: request.current.page,
                    showSizeChanger: false,
                    onChange: (page: number) => {
                        request.current.page = page
                        getList()
                    }
                }}
            >
            </Table>
        </>
    )
}
export default forwardRef(Main)